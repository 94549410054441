"use client";
import React from "react";
import Head from "next/head";
import { Stack, Paper } from "@mui/material";
import { LoginComposite } from "../../../components/login/login/composites/LoginComposite";
import { useWindowDimensions } from "@patron/utils/hooks";
import { useTranslations } from "next-intl";

export default function LoginPage() {
  const windowSize = useWindowDimensions();
  const t = useTranslations();
  return (
    <Stack
      paddingTop={10}
      alignItems="center"
      width="100%"
      id="LoginPage"
      data-cy="loginDiv"
    >
      <Head>
        <title>Novus login</title>
      </Head>
      <Paper>
        <LoginComposite />
      </Paper>
    </Stack>
  );
}
